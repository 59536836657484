import { default as datenschutzBjdGXvboxrMeta } from "/usr/src/app/pages/datenschutz.vue?macro=true";
import { default as impressum8rTlfYuIk3Meta } from "/usr/src/app/pages/impressum.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as merklistegiP002lY9PMeta } from "/usr/src/app/pages/merkliste.vue?macro=true";
import { default as speisekartev5271lKPLDMeta } from "/usr/src/app/pages/speisekarte.vue?macro=true";
export default [
  {
    name: "datenschutz",
    path: "/datenschutz",
    component: () => import("/usr/src/app/pages/datenschutz.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("/usr/src/app/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "merkliste",
    path: "/merkliste",
    component: () => import("/usr/src/app/pages/merkliste.vue")
  },
  {
    name: "speisekarte",
    path: "/speisekarte",
    component: () => import("/usr/src/app/pages/speisekarte.vue")
  }
]