import revive_payload_client_Q8ZjU1tCnj from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__3ifudicp6qql7x4stbz7geetsm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_DuDW9LAa4i from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__3ifudicp6qql7x4stbz7geetsm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_A82t08N874 from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__3ifudicp6qql7x4stbz7geetsm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_hgwhWO3o0w from "/usr/src/app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5_terser@5.3_konu3o4unweahjvz4pwbevji3q/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_NSxjPfsXiA from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__3ifudicp6qql7x4stbz7geetsm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_P8qRs415A5 from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__3ifudicp6qql7x4stbz7geetsm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_t3PCmgo5P1 from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__3ifudicp6qql7x4stbz7geetsm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_nxmcqXz3zp from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__3ifudicp6qql7x4stbz7geetsm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_3elKdDRsUL from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__3ifudicp6qql7x4stbz7geetsm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_xugf1kGpui from "/usr/src/app/node_modules/.pnpm/@nuxtjs+plausible@1.0.3_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import slideovers_JLBTCJWaY5 from "/usr/src/app/node_modules/.pnpm/@nuxt+ui@2.18.7_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5_terser@5.34.1__vue_mddixtk7yxnclpdlkjgzqr5d2q/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_75LlFCdzNg from "/usr/src/app/node_modules/.pnpm/@nuxt+ui@2.18.7_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5_terser@5.34.1__vue_mddixtk7yxnclpdlkjgzqr5d2q/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_xP0YcIzg0n from "/usr/src/app/node_modules/.pnpm/@nuxt+ui@2.18.7_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5_terser@5.34.1__vue_mddixtk7yxnclpdlkjgzqr5d2q/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_xyy6tkAUk1 from "/usr/src/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_UMJ7yVs80Q from "/usr/src/app/node_modules/.pnpm/@nuxt+icon@1.5.6_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5_terser@5.34.1__vu_ckkdg35dmwgcrcmx2e7ehbh6tm/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import sentry_client_rvvmr73p1m from "/usr/src/app/node_modules/.pnpm/@sentry+nuxt@8.34.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._7jllaqbck3oeyaaehhnaficbza/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/usr/src/app/.nuxt/sentry-client-config.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/usr/src/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_2LtTqJkqap from "/usr/src/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5_terser@5.34._xediymwubzmvh6fxa4s2jupufe/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_28wS4cqlXh from "/usr/src/app/node_modules/.pnpm/@shopware-pwa+nuxt3-module@1.0.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@shopware-pwa/nuxt3-module/plugin.ts";
import consentmanager_client_ALkKVoJBdI from "/usr/src/app/plugins/consentmanager.client.ts";
import plugin_auto_pageviews_client_oNTPhGQHd8 from "/usr/src/app/node_modules/.pnpm/@nuxtjs+plausible@1.0.3_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_Q8ZjU1tCnj,
  unhead_DuDW9LAa4i,
  router_A82t08N874,
  _0_siteConfig_hgwhWO3o0w,
  payload_client_NSxjPfsXiA,
  navigation_repaint_client_P8qRs415A5,
  check_outdated_build_client_t3PCmgo5P1,
  chunk_reload_client_nxmcqXz3zp,
  components_plugin_KR1HBZs4kY,
  prefetch_client_3elKdDRsUL,
  plugin_client_xugf1kGpui,
  slideovers_JLBTCJWaY5,
  modals_75LlFCdzNg,
  colors_xP0YcIzg0n,
  plugin_client_xyy6tkAUk1,
  plugin_UMJ7yVs80Q,
  sentry_client_rvvmr73p1m,
  sentry_client_config_o34nk2sJbg,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_2LtTqJkqap,
  plugin_28wS4cqlXh,
  consentmanager_client_ALkKVoJBdI,
  plugin_auto_pageviews_client_oNTPhGQHd8
]